import styled from 'styled-components';

const MobileCallToAction = styled.div`
  background-color: white;
  border-radius: 20px 20px 0 0;
  bottom: 0;
  box-shadow: 0px 15px 40px rgba(11, 26, 33, 0.3);
  display: flex;
  justify-content: center;
  padding: 20px 20px 25px 20px;
  position: fixed;
  width: 100%;
  z-index: 1001;

  .in-page-subnav--contextualCta-mobile,
  a,
  button {
    width: 100%;
    height: 48px;
    font-size: var(--font-size-16);
  }
`;

export { MobileCallToAction };
