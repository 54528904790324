import React from 'react';
import AnchorLinkNavigation from 'components/AnchorLinkNavigation';

const ContentfulHeader = (props) =>
  props.isAnchorMenu ? (
    <AnchorLinkNavigation
      {...props}
      inline={props.isInlineAnchorMenu}
      isHeroDark={props.isHeroDark}
    />
  ) : null;

export default ContentfulHeader;
