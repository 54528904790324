import React from 'react';
import HyperLink from 'components/Core/HyperLink';
import { STLogo } from 'components/Brand/Logos/STLogo';

const AnchorMenuLogo = ({ isHeroDark }) => (
  <HyperLink href="/">
    {isHeroDark ? (
      <STLogo className="header-logo--img white-logo"  data-testid="white-logo"/>
    ) : (
      <STLogo variation="black" className="header-logo--img tertiary-logo" data-testid="tertiary-logo"/>
    )}
  </HyperLink>
);

export default AnchorMenuLogo;
