import styled from 'styled-components';

const AnchorMenuContainer = styled.div`
  display: none;
  overflow: scroll;
  scrollbar-width: none;
  position: relative;
  width: max-content;

  &.show {
    display: block;
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .in-page-subnav--navigation {
    display: flex;
    width: max-content;
    align-items: center;
  }

  .in-page-subnav--contextualCta {
    a,
    button {
      padding: 10px 25px;
      height: auto;
    }
  }
`;

export { AnchorMenuContainer };
