const setActiveItemIndexAndScroll = (navigation, setActiveIndex, offset = 80) => {
  const scrollTo = (element) => {
    const container = document.getElementById('in-page-subnav');
    const elementLeft = element.offsetLeft;
    const elementWidth = element.offsetWidth;
    const windowWidth = window.innerWidth;
    const scrollToCenter = elementLeft - container.scrollLeft - windowWidth / 2 + elementWidth / 2;
    container.scrollBy({
      left: scrollToCenter,
      behavior: 'smooth',
    });
  };

  const container = document.getElementById('anchor-menu-container');
  const topOffset = (container?.offsetTop || 0) + (container?.offsetHeight || 0) + offset; // nav height + offset

  const offsetArray = navigation?.navigation?.map(({ url }) => {
    let offsetObj;
    const firstDashIndex = url?.indexOf('-');
    const lastDashIndex = url?.lastIndexOf('-');

    const contentfulOnlyId = url?.split('#')[1];
    const toggleIdOne = url?.substring(firstDashIndex + 1, firstDashIndex + 1 + 22);
    const toggleIdTwo = url?.substring(lastDashIndex + 1, lastDashIndex + 1 + 22);

    const ids = [contentfulOnlyId, toggleIdOne, toggleIdTwo];
    const anchors = ids.map((id) => `section-${id}`);
    const pageWidth = document.documentElement.scrollWidth;
    const pageHeight = document.documentElement.scrollHeight;

    anchors.forEach((anchor) => {
      const heading = document.getElementById(anchor);

      if (heading?.nodeType === 1) {
        const rect = heading.getBoundingClientRect();
        const isWithinPage =
          rect.top >= 0 && rect.left >= 0 && rect.bottom <= pageHeight && rect.right <= pageWidth;
        if (isWithinPage && rect.left + rect.width > 0) {
          offsetObj = { offset: rect.top, id: contentfulOnlyId };
        }
      }
    });

    return offsetObj || false;
  });

  if (offsetArray?.[0]?.offset > topOffset) {
    setActiveIndex(-1);
  } else {
    offsetArray?.forEach((offset, index) => {
      if (
        offset &&
        offset?.offset < topOffset &&
        (offsetArray[index + 1]?.offset || topOffset + 100) > topOffset
      ) {
        setActiveIndex(index);

        const selectedLabel = document.getElementById(`label-${offset.id}`);
        if (selectedLabel) {
          scrollTo(selectedLabel);
        }
      }
    });
  }
};

export default setActiveItemIndexAndScroll;
