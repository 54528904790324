import React from 'react';
import HyperLink from 'components/Core/HyperLink';
import useHash from 'hooks/useHash';
import { AnchorMenuContainer } from './styles';

const AnchorMenu = ({ activeIndex, navigation, isMobile, isSticky, desktopInline }) => {
  const [hash, updateHash] = useHash();

  const handleToggleAnchorClick = (stringAfterHash) => {
    const escapedHash = stringAfterHash.replace(
      /[!"#$%&'()*+,.\\/:;<=>?@[\\\]^`{|}~]/g,
      (match) => `\\${match}`,
    );
    updateHash(`#${escapedHash}-toggle`);

    setTimeout(() => {
      updateHash(`#${escapedHash}`);
    }, 100);
  };

  return (
    <AnchorMenuContainer
      id="in-page-subnav"
      className={`${isMobile || desktopInline ? 'show' : isSticky ? 'show' : 'hide'}`}
    >
      <div className="in-page-subnav--navigation">
        {navigation?.navigation?.map((item, index) => {
          const stringAfterHash = item?.url?.split('#')[1];
          const toggleAnchor = stringAfterHash.includes('&');
          const isContentfulId = stringAfterHash.length === 22;

          return (
            <HyperLink
              key={`in-page-subnav--link-${index}`}
              {...(!toggleAnchor && {
                href: `#${isContentfulId ? `section-${stringAfterHash}` : stringAfterHash}`,
              })}
              {...(toggleAnchor && {
                onClick: () => handleToggleAnchorClick(stringAfterHash),
              })}
              id={`label-${item?.url?.split('#')[1]}`}
            >
              <p className={`${index === activeIndex ? 'active' : ''}`} title={item.label}>
                {item.label}
              </p>
            </HyperLink>
          );
        })}
      </div>
    </AnchorMenuContainer>
  );
};

export default AnchorMenu;
