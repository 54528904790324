import React from 'react';
import ContentfulCallToAction from 'components/Contentful/ContentfulCallToAction';

import { MobileCallToAction } from './styles';

const AnchorMenuMobileCta = ({ cta }) => (
  <MobileCallToAction>
    <div className="in-page-subnav--contextualCta-mobile">
      {cta?.length > 0 && <ContentfulCallToAction {...cta[0]} />}
    </div>
  </MobileCallToAction>
);

export default AnchorMenuMobileCta;
