import styled from 'styled-components';

const AnchorMenuHeader = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 28px;
  position: absolute;
  top: ${({ top }) => `${top}px`};
  width: 100%;
  max-width: 1252px;
  z-index: 1;

  svg {
    margin-right: 40px;
    margin-top: 15px;
    position: relative;
    width: 250px;
    height: 46px;
  }

  .in-page-subnav--contextualCta-drift {
    background: transparent;
    border: none;
    display: flex;
    align-items: center;

    svg {
      width: 32px;
    }

    p {
      color: white;
      margin: auto 0 0 15px;
      font-size: var(--font-size-20);
      font-weight: var(--font-weight-300);
    }

    &:hover {
      cursor: pointer;
    }
  }

  .in-page-subnav--contextualCta {
    a,
    button {
      box-shadow: none;
      font-size: var(--font-size-16);
      line-height: initial;
      margin-left: 16px;
      padding: 16px 45px;
    }
  }

  @media (max-width: 767px) {
    margin-left: 0;
    padding: 20px;
    z-index: 1;

    .in-page-subnav--contextualCta {
      a,
      button {
        padding: 0;
      }
    }

    svg {
      margin-right: 40px;
      margin-top: 0;
      position: relative;
      width: 160px;
      height: 26px;
    }
  }
`;

const Container = styled.div`
  background: ${({ isScrolled }) => (isScrolled ? 'white' : 'transparent')};
  box-shadow: ${({ isSticky }) => (isSticky ? '0px 3px 10px rgba(11, 26, 33, 0.08)' : 'none')};
  padding: 15px 0px;
  position: ${({ isSticky }) => (isSticky ? 'fixed' : 'relative')};
  display: ${({ isDesktop, isSticky }) =>
    isDesktop && !isSticky ? 'none' : isDesktop ? 'flex' : !isDesktop && 'flex'};
  justify-content: center;
  top: ${({ bannerHeight, isSticky }) => (isSticky ? `${bannerHeight}px` : 0)};
  width: 100%;
  z-index: ${({ isDesktop, inline, isSticky }) =>
    isDesktop && !isSticky && !inline ? '-1' : 'var(--z-anchor-subnav)'};
  height: ${({ isDesktop, isSticky }) => (isDesktop && !isSticky ? '1px' : 'auto')};
  transition: transform 0.3s ease-out;
  will-change: transform;

  svg {
    margin-right: 40px;
    position: relative;
    width: 250px;
  }

  .in-page-subnav--contextualCta {
    a,
    button {
      box-shadow: none;
      font-size: var(--font-size-16);
      line-height: initial;
      margin-left: 16px;
      padding: 16px 45px;
      width: max-content;
      min-width: 170px;
      height: 50px;
    }
  }

  @media (min-width: 767px) {
    -webkit-transition: background 0.5s ease-out;
    -moz-transition: background 0.5s ease-out;
    -o-transition: background 0.5s ease-out;
    transition: background 0.5s ease-out;

    .in-page-subnav--contextualCta {
      a,
      button {
        margin-left: 0;
        padding: 13px 17px;
      }
    }

    svg {
      width: 160px;
    }
  }
`;

const InnerContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: ${({ desktopInline }) => (desktopInline ? 'space-around' : 'space-between')};
  margin: 0px 28px;
  max-width: 1196px;
  position: relative;
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 1000;

  ${({ isMobile, isScrolled, $afterFirst }) => {
    const scrolledPastFirstItem = isMobile && isScrolled && $afterFirst;

    if (scrolledPastFirstItem) {
      return `
        &:before {
        background-image: linear-gradient(to left, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        bottom: 15px;
        content: '';
        height: 100%;
        left: 0;
        pointer-events: none;
        position: absolute;
        top: 0;
        width: 15%;
        z-index: 1;
      }
      `;
    }
  }}

  ${({ isMobile, isScrolled, $beforeLast }) => {
    const scrolledBeforeLastItem = isMobile && isScrolled && $beforeLast;

    if (scrolledBeforeLastItem) {
      return `
      &:after {
        content: '';
        background-image: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1));
        bottom: 15px;
        height: 100%;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 0;
        width: 15%;
        z-index: 1;
      }
      `;
    }
  }}

  a:last-child {
    p {
      margin-right: 0;
    }
  }

  p {
    font-size: var(--font-size-16);
    color: var(--darkest);
    margin: 0 40px 0 0;
    width: max-content;
    text-decoration: none;
    position: relative;

    &:hover {
      font-weight: var(--font-weight-700);
    }

    &:before {
      display: block;
      content: attr(title);
      font-weight: var(--font-weight-700);
      height: 0;
      overflow: hidden;
      visibility: hidden;
    }

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 0;
      height: 1px;
      background: var(--darkest);
      -webkit-transition: width 250ms ease-in-out;
      -moz-transition: width 250ms ease-in-out;
      -o-transition: width 250ms ease-in-out;
      transition: width 250ms ease-in-out;
    }
  }

  .active {
    font-weight: var(--font-weight-700);
    position: relative;
    text-decoration: none;

    &:after {
      width: 100%;
    }
  }
`;

export { AnchorMenuHeader, Container, InnerContainer };
